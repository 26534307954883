// AuthCallback.js
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const AuthCallback = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Extract the token and redirectUrl from the URL fragment
    const hash = window.location.hash;
    const params = new URLSearchParams(hash.substring(1)); // Remove the '#'
    const token = params.get("session_token");
    const redirectUrl = params.get("redirectUrl") || "/";

    if (token) {
      // Store the token securely
      localStorage.setItem("session_token", token);
    }

    // Redirect to the original page
    window.location.href = redirectUrl;
  }, []);

  return null; // Optionally, display a loading indicator
};

export default AuthCallback;
