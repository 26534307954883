// Login.js
import React, { useContext, useEffect } from "react";
import "./Login.css";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import CorpMemphRest from "./corporate-memphis-restaurant.png";
import GoogleLogo from "./google-logo.png";
import { AppContext } from "../../App";
import Cookie from "js-cookie";

const Login = (props) => {
  const { user, setUser } = useContext(AppContext);

  const currentUrl = window.location.href;

  const exit = (e) => {
    e?.stopPropagation();
    props.exit(false);
  };

  useEffect(() => {
    const metaCookie = Cookie.get("meta_cookie");
    if (metaCookie && user) {
      // Link the metaCookie activities to the user account
      axios.post(`${process.env.REACT_APP_DOMAIN}/api/users/link-meta`, {
        metaCookie,
        userId: user._id,
      });
    }
  }, [user]);

  const loginWithGoogle = () => {
    const redirectUri = encodeURIComponent(
      "https://platefol.com/auth/google/callback"
    );
    window.location.href = `https://api.platefol.com/auth/google?redirectUrl=${encodeURIComponent(
      currentUrl
    )}&frontendRedirectUri=${redirectUri}`;
  };

  return (
    <div className="Login" onClick={exit}>
      {/* <img src={CorpMemphRest} alt="background" className="login-background" /> */}
      <div className="login-background"></div>
      <div className="login-card" onClick={(e) => e.stopPropagation()}>
        <h2 className="login-title">Platefol is the world's smartest menu.</h2>
        <h3 className="login-subtitle">
          Don't worry. We’re allergic to spam, too—unless it’s the fried kind .
          Create an account, get mouth-watering previews, and help us match you
          with dishes you’ll love.
        </h3>
        <button className="login-with" onClick={loginWithGoogle}>
          <img src={GoogleLogo} className="google-logo" alt="Google Logo" />
          Login with Google
        </button>
      </div>
    </div>
  );
};

export default Login;
