import React, { useState, useEffect } from "react";
import "./Modal.css";

const Modal = ({ children, show, header, onClose }) => {
  const [startY, setStartY] = useState(0);
  const [currentY, setCurrentY] = useState(0);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    const handleMove = (event) => {
      if (!isDragging) return;

      // Prevent default scrolling behavior
      event.preventDefault();

      const clientY = event.touches ? event.touches[0].clientY : event.clientY;
      const deltaY = clientY - startY;
      setCurrentY(Math.max(0, deltaY)); // Prevent moving above the screen
    };

    const handleEnd = () => {
      setIsDragging(false);
      if (currentY > 200) {
        // If dragged more than 200 pixels, close the modal
        new Promise((resolve) => {
          document
            .querySelector(".modal-content")
            .classList.toggle("hide-modal");
          setTimeout(() => {
            resolve();
          }, 300);
        }).then(() => {
          onClose();
        });
      } else {
        setCurrentY(0); // Reset position if not dragged far enough
      }
    };

    if (isDragging) {
      document.addEventListener("mousemove", handleMove);
      document.addEventListener("mouseup", handleEnd);
      document.addEventListener("touchmove", handleMove, { passive: false }); // Important: passive: false
      document.addEventListener("touchend", handleEnd);
    }

    return () => {
      document.removeEventListener("mousemove", handleMove);
      document.removeEventListener("mouseup", handleEnd);
      document.removeEventListener("touchmove", handleMove);
      document.removeEventListener("touchend", handleEnd);
    };
  }, [isDragging, startY, currentY, onClose]);

  const handleStart = (event) => {
    const clientY = event.touches ? event.touches[0].clientY : event.clientY;
    setStartY(clientY);
    setIsDragging(true);
  };

  // Handler that closes the modal when the user clicks outside of modal-content
  const closeOnOutsideClick = (event) => {
    const modalContent = document.querySelector(".modal-content");

    // Check if the clicked element is outside of modal-content
    if (modalContent && !modalContent.contains(event.target)) {
      onClose();
    }
  };

  if (!show) {
    return null;
  }

  return (
    <div className="Modal" onClick={closeOnOutsideClick}>
      <div
        className="modal-content"
        style={{ transform: `translateY(${currentY}px)` }}
      >
        <div
          className="modal-top"
          onMouseDown={handleStart}
          onTouchStart={handleStart}
        >
          <div className="handle"></div>
          <div className="modal-header">{header}</div>
        </div>
        <div className="modal-body">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
