import React, { useState, useEffect, useRef } from "react";
import "./Comments.css";
import SubmitArrow from "./submit-arrow.png";
import BlackKebab from "./black-kebab.png";
import axios from "axios";

const Comments = (props) => {
  const [value, setValue] = useState("");
  const [comments, setComments] = useState([]);
  const [expandedComments, setExpandedComments] = useState({});
  const [openMenuCommentId, setOpenMenuCommentId] = useState(null);

  const inputRef = useRef();

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_DOMAIN}/api/restaurant/comments/${props.resId}/${props.id}`,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setComments(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [props.id, props.resId]);

  const selectInput = (e) => {
    e.preventDefault();
    e.stopPropagation();
    inputRef.current.focus();
  };

  const submitComment = async (e) => {
    e.preventDefault();

    await inputRef.current.blur();

    if (!props.user) {
      await props.redirectToLogin(true);
      await props.exit(true);
      return;
    }

    if (value.length > 0) {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_DOMAIN}/api/restaurant/comments/${props.resId}/${props.id}`,
          {
            content: value,
          },
          {
            withCredentials: true,
          }
        );

        setComments([...comments, res.data]);
      } catch (error) {
        console.error("Error submitting comment:", error);
      }
    }

    await setValue("");
  };

  const changeValue = (e) => {
    setValue(e.target.value);
  };

  const handleReadMore = (id) => {
    setExpandedComments((prevState) => ({ ...prevState, [id]: true }));
  };

  const handleReadLess = (id) => {
    setExpandedComments((prevState) => ({ ...prevState, [id]: false }));
  };

  const handleDeleteComment = async (commentId) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_DOMAIN}/api/restaurant/comments/${props.resId}/${props.id}/${commentId}`,
        {
          withCredentials: true,
        }
      );
      setComments(comments.filter((comment) => comment._id !== commentId));
      setOpenMenuCommentId(null);
    } catch (error) {
      console.error("Error deleting comment:", error);
    }
  };

  const handleReportComment = async (commentId) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_DOMAIN}/api/restaurant/comments/${props.resId}/${props.id}/${commentId}/report`,
        {},
        {
          withCredentials: true,
        }
      );
      alert("Comment reported.");
      setOpenMenuCommentId(null);
    } catch (error) {
      console.error("Error reporting comment:", error);
    }
  };

  const formatTimeSince = (createdAt) => {
    const now = new Date();
    const createdTime = new Date(createdAt);
    const elapsedTime = now - createdTime;

    const seconds = Math.floor(elapsedTime / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    if (seconds < 60) return "Just Now";
    if (minutes < 60) return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    if (hours < 24) return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    if (days < 7) return `${days} day${days > 1 ? "s" : ""} ago`;
    if (weeks < 4) return `${weeks} week${weeks > 1 ? "s" : ""} ago`;
    return `${years} year${years > 1 ? "s" : ""} ago`;
  };

  const parseHashtags = (content) => {
    const hashtagRegex = /(#\w+)/g;
    return content.split(hashtagRegex).map((part, index) => {
      if (hashtagRegex.test(part)) {
        return (
          <a key={index} className="hashtag">
            {part}
          </a>
        );
      }
      return part;
    });
  };

  return (
    <div className="Comments">
      <div className="comment-list">
        {comments.map((comment) => {
          const commentId = comment._id || comment.id;
          const isExpanded = expandedComments[commentId] || false;
          const isLongComment = comment.content.length > 150;
          const displayedContent = isExpanded
            ? comment.content
            : comment.content.slice(0, 150);

          const elapsedTime = formatTimeSince(comment.createdAt);

          return (
            <div key={commentId} className="comment">
              <img
                className="comment-avatar"
                src={comment.userId.picture}
                alt={`${comment.userId.name}'s avatar`}
              />
              <div className="comment-body">
                <div className="comment-author">
                  {comment.userId.name}{" "}
                  <span className="comment-time">• {elapsedTime}</span>
                  <img
                    src={BlackKebab}
                    alt="Options"
                    className="comment-options-icon"
                    onClick={() =>
                      setOpenMenuCommentId(
                        openMenuCommentId === commentId ? null : commentId
                      )
                    }
                  />
                </div>
                {openMenuCommentId === commentId && (
                  <div className="comment-options-menu">
                    {props.user && props.user._id === comment.userId._id && (
                      <div
                        className="comment-option"
                        onClick={() => handleDeleteComment(commentId)}
                      >
                        Delete
                      </div>
                    )}
                    <div
                      className="comment-option"
                      onClick={() => handleReportComment(commentId)}
                    >
                      Report
                    </div>
                  </div>
                )}
                <div className="comment-content">
                  {parseHashtags(displayedContent)}
                  {isLongComment && !isExpanded && (
                    <>
                      ...
                      <span
                        className="read-more"
                        onClick={() => handleReadMore(commentId)}
                      >
                        Read More
                      </span>
                    </>
                  )}
                  {isExpanded && isLongComment && (
                    <span
                      className="read-less"
                      onClick={() => handleReadLess(commentId)}
                    >
                      Show Less
                    </span>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="comment-actions">
        <form
          className="comment-input-container"
          onClick={selectInput}
          onSubmit={submitComment}
        >
          <input
            type="text"
            value={value}
            onChange={changeValue}
            className="comment-input"
            placeholder="Add a comment..."
            ref={inputRef}
          />
          {value.length > 0 && (
            <button
              className="comment-submit"
              type="submit"
              onClick={submitComment}
            >
              <img className="submit-arrow" src={SubmitArrow} alt="send" />
            </button>
          )}
        </form>
      </div>
    </div>
  );
};

export default Comments;
