// RequireRole.js
import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AppContext } from "../../App";
import Loading from "./Loading";

function RequireRole({ role, children }) {
  const { user, userLoaded } = useContext(AppContext);

  if (!userLoaded) {
    // Still loading, so show the Loading component
    return <Loading />;
  }

  if (user && user.roles && user.roles.includes(role)) {
    return children;
  } else {
    // Redirect to a "Not Authorized" page or the homepage
    return <Navigate to="/" replace />;
  }
}

export default RequireRole;
