import React, { useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../App";
import "./Toolbar.css";
import "./Themes.css";
import BackIcon from "./back-arrow.svg";
import MuteIcon from "./mute.svg";
import GridIcon from "./grid-icon.png";

const DiscoverToolbar = (props) => {
  const activeSectionRef = useRef("popular");

  const navigation = useNavigate();
  const context = useContext(AppContext);

  const changeSectionToolbar = async () => {
    const section = document.querySelector(
      `.section-title#${activeSectionRef.current}`
    );
    const sectionBar = document.querySelector(".section-bar");
    const sectionTitle = sectionBar.querySelector(
      `.section#${activeSectionRef.current}`
    );
    const distance = sectionTitle.getBoundingClientRect().left;
    const toolbarHeight =
      document.querySelector(".Toolbar").getBoundingClientRect().height + 10;

    if (distance >= 70 || distance <= -70) {
      await sectionBar.scrollTo({
        left: sectionTitle.offsetLeft - 20,
        behavior: "smooth",
      });
    }
  };

  const handleMute = async (e) => {
    e.stopPropagation();

    // Get video in the middle of the screen
    const videos = await document.querySelectorAll("video");

    if (videos.length) {
      const bottom = window.innerHeight;
      let video = await null;

      await videos.forEach(async (each) => {
        // Check if the video is in the middle of the screen
        const rect = each.getBoundingClientRect();
        if (
          rect.top + rect.height / 2 >= 0 &&
          rect.top + rect.height <= bottom
        ) {
          // Switch the video to mute or unmute
          let video = await each;
          let videoId = await video.parentElement.parentElement.id;

          let specificVideo = await document.querySelector(`#${videoId} video`);

          if (specificVideo.autoplay) {
            await specificVideo.pause();
            specificVideo.autoplay = await false;
            specificVideo.muted = await false;
            await specificVideo.play();
          } else {
            await specificVideo.pause();
            specificVideo.muted = await true;
            specificVideo.autoplay = await true;
            await specificVideo.play();
          }
        }
      });
    }
  };

  const goToHomePage = async (e) => {
    e.stopPropagation();

    // Set lastURL in loaclStorage to the ccurrent URL
    await localStorage.setItem("lastURL", window.location.pathname);
    await navigation("/");
  };

  return (
    <div className="ActiveToolbar DiscoverToolbar">
      <div className="section">
        <img
          className="grid-icon"
          src={GridIcon}
          alt="Grid Icon"
          onClick={props.toggleBottomToolbar}
        />
      </div>
      <div onClick={goToHomePage} className={`platefol logo-section`}>
        platefol
      </div>
      <div className="section">
        <div className="action-button">
          <img
            className="icon"
            src={MuteIcon}
            onClick={handleMute}
            alt="Mute Icon"
          ></img>
        </div>
      </div>
    </div>
  );
};

export default DiscoverToolbar;
