// UserProfile.js
import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { debounce } from "lodash";
import Thumbnail from "../components/Thumbnail";
import "./UserProfile.css";
import BottomToolbar from "../components/shared/BottomToolbar";

const UserProfile = (props) => {
  const { userId } = useParams();
  const [user, setUser] = useState(null);
  const [topToolbarHidden, setTopToolbarHidden] = useState(true);
  const [likedItems, setLikedItems] = useState([]);
  const [activeCarousel, setActiveCarousel] = useState("liked");

  const handleScroll = useCallback(
    debounce(() => {
      const likedItems = document.querySelector(".liked-items");
      const postItems = document.querySelector(".post-items");
      const scrollPosition =
        document.querySelector(".content-carousel").scrollLeft;

      if (likedItems && postItems) {
        const postOffset = postItems.offsetLeft;

        if (scrollPosition >= postOffset - 50) {
          setActiveCarousel("posts");
        } else {
          setActiveCarousel("liked");
        }
      }
    }, 100), // Adjust debounce delay as needed
    []
  );

  // Create listener on scroll that sets topToolbarHidden state when scrollTop is greater than the top of .liked-items
  useEffect(() => {
    const scrollToolbar = () => {
      const likedItems = document.querySelector(".thumbnails");
      if (likedItems) {
        if (window.scrollY > likedItems.offsetTop) {
          setTopToolbarHidden(false);
        } else {
          setTopToolbarHidden(true);
        }
      }
    };

    window.addEventListener("scroll", scrollToolbar);
    return () => window.removeEventListener("scroll", scrollToolbar);
  }, []);

  useEffect(() => {
    const carousel = document.querySelector(".content-carousel");
    carousel.addEventListener("scroll", handleScroll);
    return () => carousel.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    document.querySelector("#theme-color").content = "#FFF";
    document.body.style.backgroundColor = "#FFF";
  }, []);

  useEffect(() => {
    const getUserProfile = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_DOMAIN}/api/users/${userId}`
        );
        setUser(response.data);
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };
    getUserProfile();

    const fetchUserLikedItems = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_DOMAIN}/api/users/${userId}/liked-items`
        );
        setLikedItems(response.data.items);
      } catch (error) {
        console.error("Error fetching user liked items:", error);
      }
    };

    fetchUserLikedItems();
  }, []);

  const smoothScrollTo = (element) => {
    return new Promise((resolve) => {
      const carousel = document.querySelector(".content-carousel");
      carousel.scrollTo({
        left: element.offsetLeft,
        behavior: "smooth",
      });

      const handleScrollEnd = () => {
        carousel.removeEventListener("scroll", handleScrollEnd);
        resolve(); // Scroll completed
      };

      carousel.addEventListener("scroll", handleScrollEnd);
    });
  };

  const toggleCarouselLiked = async (e) => {
    e.preventDefault();
    if (activeCarousel !== "liked") {
      await smoothScrollTo(document.querySelector(".liked-items"));
      setActiveCarousel("liked"); // Update state after scroll completes
    }
  };

  const toggleCarouselPosts = async (e) => {
    e.preventDefault();
    if (activeCarousel !== "posts") {
      await smoothScrollTo(document.querySelector(".post-items"));
      setActiveCarousel("posts"); // Update state after scroll completes
    }
  };

  const handleLogout = async () => {
    const logoutConfirmation = await window.confirm(
      "Are you sure you want to log out?"
    );

    if (logoutConfirmation) {
      //Delete session_token from cookies
      window.location.href = await `${
        process.env.REACT_APP_DOMAIN
      }/api/logout?redirectUrl=${
        window.localStorage.getItem("lastDiscover") || "/"
      }`;
    }
  };

  return (
    <>
      <div
        className={`user-scrolling-toolbar${topToolbarHidden ? " hidden" : ""}`}
      >
        <img
          className="profile-pic"
          src={user?.picture}
          alt={`${user?.name}'s profile`}
        />
        <div className="user-info">
          <h1 className="users-name">{user?.name}</h1>
        </div>
      </div>

      <div className="profile-actions">
        <div className="logo platefol">platefol</div>
        <button className="logout-button" onClick={handleLogout}>
          Logout
        </button>
      </div>

      <div className="user-profile">
        <div className="user-profile-hero">
          {user && (
            <div className="user-info">
              <img
                className="profile-pic"
                src={user.picture}
                alt={`${user.name}'s profile`}
              />
              <h1 className="users-name">{user.name}</h1>
              <h3 className="friend-count">
                <span className="count">{user.friendCount}</span> Friend
                {user.friendCount > 1 && "s"}
              </h3>
            </div>
          )}
        </div>

        <div className="toggle-toolbar">
          <div className="content-carousel-toggle">
            <div className="toggle-icons">
              <div
                className={`toggle-icon ${
                  activeCarousel === "liked" ? "active" : ""
                }`}
                onClick={toggleCarouselLiked}
              >
                Liked
              </div>
              <div
                className={`toggle-icon ${
                  activeCarousel === "posts" ? "active" : ""
                }`}
                onClick={toggleCarouselPosts}
              >
                Posts
              </div>
            </div>
            <div className="toggle-slider">
              <div
                className={`slider-container${
                  activeCarousel === "posts" ? " active" : ""
                }`}
              >
                <div className="slider" />
              </div>
            </div>
          </div>
        </div>

        <div className="content-carousel">
          <div className="liked-items">
            <div className="thumbnails">
              {likedItems.map((item) => (
                <Thumbnail key={item.item._id} item={item.item} />
              ))}
            </div>
          </div>
          <div className="post-items">
            <div className="coming-soon">
              We're still cooking this section. Check back soon!
            </div>
          </div>
        </div>
      </div>

      <BottomToolbar show active="profile" />
    </>
  );
};

export default UserProfile;
