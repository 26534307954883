import React, { useEffect } from "react";
import "./Thumbnail.css"; // Create this CSS file for styling

function Thumbnail({ item }) {
  useEffect(() => {}, [item]);

  return (
    <a className="thumbnail">
      {item.content && item.content.src != "/" ? (
        <img src={item.content.thumbnail} alt={item.title} />
      ) : (
        <div className="no-thumbnail"></div>
      )}
    </a>
  );
}

export default Thumbnail;
