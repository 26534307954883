import React, { useContext } from "react";
import LoadingRotator from "./LoadingRotator.svg";
import "./Loading.css";
import { AppContext } from "../../App";

const Loading = () => {
  const context = useContext(AppContext);

  return (
    <div className="Loading">
      <svg
        className="spinner"
        width="88"
        height="88"
        viewBox="0 0 88 88"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M79 44C79 38.0521 77.4842 32.2023 74.5957 27.0028C71.7072 21.8034 67.5413 17.4258 62.4912 14.2834C57.4412 11.141 51.6736 9.33744 45.733 9.04294C39.7924 8.74843 33.8747 9.97272 28.5386 12.6002C23.2025 15.2278 18.6241 19.1718 15.2355 24.0601C11.8469 28.9483 9.75991 34.6195 9.17163 40.5383C8.58334 46.457 9.51312 52.4281 11.8732 57.8877C14.2333 63.3473 17.9458 68.1155 22.6603 71.742"
          stroke={context.color}
          stroke-width="13"
        />
      </svg>
    </div>
  );
};

export default Loading;
