import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  lazy,
  Suspense,
} from "react";
import { useNavigate } from "react-router-dom";
import "./ScrollMenu.css";
import axios from "axios";
import "../components/shared/Themes.css";
import ReactPlayer from "react-player";
import { Helmet } from "react-helmet";
import DiscoverToolbar from "../components/shared/DiscoverToolbar";
import BottomToolbar from "../components/shared/BottomToolbar";
import EmptyLike from "../components/shared/empty-like.png";
import Like from "../components/shared/like.png";
import CommentIcon from "../components/shared/comment.png";
import ShareIcon from "../components/shared/share.png";
import KebabIcon from "../components/shared/kebab.png";
import verifiedBadge from "../components/shared/verified-badge-white.png";
import Modal from "../components/shared/Modal";
import Login from "../components/shared/Login";
import Comments from "../components/shared/Comments";
import { AppContext } from "../App";
import Cookie from "js-cookie";
import { v4 as uuid } from "uuid";

const ImgFile = lazy(() => import("../components/shared/ImgFile"));

/* 
  Component: AutoPlaySilentVideo
  Purpose: Autoplay video silently in a loop with no controls, used to display food videos.
*/
const AutoPlaySilentVideo = (props) => {
  const videoRef = useRef(null);
  const [playedFraction, setPlayedFraction] = useState(0);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.muted = true; // Mute video by default
    }
  }, []);

  // Reset video when not playing
  useEffect(() => {
    if (!props.startPlaying && videoRef.current) {
      videoRef.current.seekTo(0);
    }
  }, [props.startPlaying]);

  return (
    <div className="video-container">
      <ReactPlayer
        ref={videoRef}
        className={props.className}
        loop
        playing={props.startPlaying}
        muted
        playsinline={true}
        url={[{ src: props.src, type: "video/mp4" }]}
        width="100%"
        height="100%"
        controls={false}
        alt={props.alt}
        config={{
          file: {
            attributes: {
              controlsList: "nofullscreen",
            },
          },
        }}
        onProgress={({ played }) => {
          setPlayedFraction(played);
        }}
        onPlay={() => {
          console.log("Playing video");
        }}
        onPause={() => {
          console.log("Pausing video");
        }}
      />
      <div className="progress-bar-container">
        <div
          className="progress-bar"
          style={{ width: `${playedFraction * 100}%` }}
        ></div>
      </div>
    </div>
  );
};

/* 
  Component: NoContent
  Purpose: Display fallback content when no media is available for a restaurant item.
*/
const NoContent = (props) => {
  const fileInput = useRef(null);

  // Trigger file input dialog
  const chooseFile = () => {
    fileInput.current.click();
  };

  return (
    <div className="no-content-background">
      <div className="no-content">
        <div className="no-content-prompt">
          <div className="oops">Oops!</div>
          <div className="upload-description">
            No visual content. Provide your own photos or video of{" "}
            {String(props.itemName).toLowerCase().slice(0, 4) == "the "
              ? ""
              : "the"}{" "}
            {props.itemName} and we'll dish it up.
          </div>
        </div>
      </div>
    </div>
  );
};

/* 
  Component: SecondaryActions
  Purpose: Handle actions like uploading media when no content is available.
*/
const SecondaryActions = (props) => {
  const fileInput = useRef(null);

  const [uploaded, setUploaded] = useState(false);
  const [uploading, setUploading] = useState(false);

  // Trigger file input dialog
  const chooseFile = () => {
    fileInput.current.click();
  };

  // Handle file attachment
  const attachFile = (e) => {
    e.preventDefault();

    const files = e.target.files;

    if (!files || files.length === 0) {
      console.error("No files found.");
      return;
    }

    const file = files[0];

    const readerForContent = new FileReader();
    readerForContent.readAsDataURL(file);
    readerForContent.onloadend = () => {
      const base64String = readerForContent.result.split(",")[1];

      const postBody = {
        contentType: file.type,
        data: base64String,
        restaurantId: props.restaurantId,
        itemId: props.itemId,
      };

      setUploading(true);

      // Upload file to server
      axios
        .post(
          `${process.env.REACT_APP_DOMAIN}/api/restaurant/edit/pending-media`,
          postBody
        )
        .then((res) => {
          console.log(res);
        })
        .then(() => {
          setUploading(false);
          setUploaded(true);
        })
        .catch((err) => {
          console.error(err);
        });
    };
  };

  return (
    <div className="secondary-actions">
      <button
        className={`action-button upload-button${uploaded ? " saved" : ""}`}
        onClick={chooseFile}
      >
        {uploading ? "Uploading..." : uploaded ? "Saved!" : "Upload"}
      </button>
      <input
        type="file"
        ref={fileInput}
        style={{ display: "none" }}
        accept=".jpg, .jpeg, .png, .gif, .mp4"
        onChange={attachFile}
      />
    </div>
  );
};

// Calculate the initial page reference once, outside the component's render cycle
const calculateInitialPageRef = (returnToIndex) =>
  Math.floor(returnToIndex / 10) + 1 || 1;

/* 
  Component: Discover
  Purpose: Main component for displaying the discover page with menu items, 
  managing user interactions, and fetching data.
*/
const Discover = () => {
  // Use the AppContext to access user state
  const { user, setUser } = React.useContext(AppContext);

  // State management for the component
  const [menuItems, setMenuItems] = useState([]);
  const [inView, setInView] = useState(null);
  const [itemWindow, setItemWindow] = useState([0, 5]);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [following, setFollowing] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [expandedItems, setExpandedItems] = useState({});
  const [likedItems, setLikedItems] = useState({});
  const [initialScrollCompleted, setInitialScrollCompleted] = useState(false); // Track initial scroll status
  const [requestingUser, setRequestingUser] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showBottomToolbar, setShowBottomToolbar] = useState(false);

  // References for managing scrolling and other states
  const loadingRef = useRef(false);
  const itemRefs = useRef([]);
  const observerRef = useRef(null);
  const navigate = useNavigate();
  const lastTap = useRef(0);
  const containerRef = useRef(null);
  const hasScrolledToIndex = useRef(false);

  // Extract search parameters from URL
  const { search } = window.location;
  const params = new URLSearchParams(search);

  const lat = params.get("lat");
  const lng = params.get("lng");
  const returnToIndex = parseInt(params.get("returnto"), 10);

  // Initialize pageRef
  const pageRef = useRef(calculateInitialPageRef(returnToIndex));

  // References for managing pagination and scrolling
  const hasMoreRef = useRef(true);
  const hasLessRef = useRef(pageRef.current > 1);

  /* 
    Function: fetchRestaurants
    Purpose: Fetch restaurant data based on current latitude and longitude.
  */
  const fetchRestaurants = useCallback(
    async (direction = "forward") => {
      if (loadingRef.current) return;

      loadingRef.current = true;

      const pageSize = 10;
      let pageToFetch;

      if (direction === "forward") {
        pageToFetch = pageRef.current;
        pageRef.current += 1;
      } else {
        pageRef.current -= 1;
        pageToFetch = pageRef.current;
      }

      // Ensure pageToFetch is at least 1
      if (pageToFetch < 1) {
        loadingRef.current = false;
        hasLessRef.current = false;
        return;
      }

      const res = await axios.post(
        `${process.env.REACT_APP_DOMAIN}/api/restaurant/discover-by-lat-long`,
        {
          lat: latitude, // Use state variable
          lng: longitude, // Use state variable
          page: pageToFetch,
          limit: pageSize,
        }
      );

      if (res.data.length === 0) {
        if (direction === "forward") {
          hasMoreRef.current = false;
        } else {
          hasLessRef.current = false;
        }
      } else {
        // Assign absolute indices to items
        const startIndex = (pageToFetch - 1) * pageSize;
        res.data.forEach((item, index) => {
          item.absoluteIndex = startIndex + index;
        });

        if (direction === "forward") {
          console.log("Appending items to the end of the list");
          setMenuItems((prevItems) => [...prevItems, ...res.data]);
        } else {
          console.log("Prepending items to the start of the list");
          setMenuItems((prevItems) => [...res.data, ...prevItems]);
        }
      }

      loadingRef.current = false;
    },
    [latitude, longitude]
  );

  useEffect(() => {
    setRequestingUser(false);
  }, []);

  const fetchSharedItem = async (shareID) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DOMAIN}/api/shares/${shareID}`
      );
      const sharedItem = response.data.item;

      // Prepend the shared item to the menuItems array
      setMenuItems((prevItems) => [sharedItem, ...prevItems]);
    } catch (error) {
      console.error("Error fetching shared item:", error);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const shareID = params.get("sid");

    if (shareID) {
      // Log the share click
      logShareClick(shareID);

      // Fetch the shared item and ensure it's displayed first
      fetchSharedItem(shareID);
    }
  }, []);

  // Fetch data when component mounts or lat/lng changes
  useEffect(() => {
    const getLocation = async () => {
      if (params.get("lat") && params.get("lng")) {
        // If lat/lng are present in the URL, use those values
        setLatitude(parseFloat(lat));
        setLongitude(parseFloat(lng));
      } else {
        // If lat/lng are not present, request user's location
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setLatitude(position.coords.latitude);
            setLongitude(position.coords.longitude);
          },
          (error) => {
            // Fallback to default location if permission is denied or there's an error
            console.error("Error getting location: ", error);
            setLatitude(36.1610282); // Default latitude for Nashville, TN
            setLongitude(-86.7773344); // Default longitude for Nashville, TN
          }
        );
      }
    };

    getLocation();
  }, []);

  useEffect(() => {
    if (latitude !== null && longitude !== null) {
      fetchRestaurants();
    }
  }, [latitude, longitude]);

  // Set up IntersectionObserver to load more items when the user scrolls
  useEffect(() => {
    if (observerRef.current) observerRef.current.disconnect();

    observerRef.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const index = itemRefs.current.findIndex(
              (item) => item === entry.target
            );

            if (index !== -1) {
              const item = menuItems[index];
              const absoluteIndex = item.absoluteIndex;

              setInView(absoluteIndex);

              // Replace the URL with the current absolute index
              if (initialScrollCompleted) {
                const newUrl = new URL(window.location.href);
                newUrl.searchParams.set("returnto", absoluteIndex);
                window.history.replaceState({}, "", newUrl);
              }

              // Load more items if necessary
              if (
                absoluteIndex >=
                  menuItems[menuItems.length - 1].absoluteIndex - 3 &&
                hasMoreRef.current &&
                !loadingRef.current
              ) {
                fetchRestaurants("forward");
              } else if (
                absoluteIndex === menuItems[0].absoluteIndex &&
                hasLessRef.current &&
                !loadingRef.current
              ) {
                fetchRestaurants("backward");
              }
            }
          }
        });
      },
      { threshold: 0.1 }
    );

    itemRefs.current.forEach((item) => {
      if (item) observerRef.current.observe(item);
    });

    return () => {
      observerRef.current.disconnect();
    };
  }, [menuItems, fetchRestaurants]);

  // Load previous pages if a returnToIndex is specified
  useEffect(() => {
    if (
      !hasScrolledToIndex.current &&
      returnToIndex !== null &&
      menuItems.length > 0
    ) {
      const pageSize = 10;
      const targetPage = Math.floor(returnToIndex / pageSize) + 1;

      const loadPreviousPages = async () => {
        while (
          pageRef.current < targetPage &&
          menuItems[menuItems.length - 1]?.absoluteIndex < returnToIndex
        ) {
          await fetchRestaurants("forward");
        }

        const targetItemIndex = menuItems.findIndex(
          (item) => item.absoluteIndex === returnToIndex
        );

        if (targetItemIndex !== -1 && itemRefs.current[targetItemIndex]) {
          itemRefs.current[targetItemIndex].scrollIntoView({
            behavior: "instant",
            block: "center",
          });
          setInView(returnToIndex);
          hasScrolledToIndex.current = true;
          setInitialScrollCompleted(true);
        }
      };

      loadPreviousPages();
    }
  }, [returnToIndex, menuItems, fetchRestaurants]);

  // Set page background color on load
  useEffect(() => {
    document.querySelector("#theme-color").content = "#000";
    document.body.style.backgroundColor = "#000";
  }, []);

  const toggleBottomToolbar = () => {
    setShowBottomToolbar((prev) => !prev);
  };

  // Function handlers for various user actions
  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  const openCommentModal = (e, item) => {
    e.stopPropagation();

    setModalContent({
      title: "Comments",
      content: (
        <Comments
          id={item._id} // Use item._id as the comment ID
          resId={item.id} // Use item.id as the restaurant ID
          user={user}
          exit={setRequestingUser}
          redirectToLogin={setShowLoginModal}
        />
      ),
    });
    setModalOpen(true);
  };

  const openShareModal = (e) => {
    e.stopPropagation();

    setModalContent({ title: "Share", content: "This is a share." });
    setModalOpen(true);
  };

  const openKebabModal = (e) => {
    e.stopPropagation();

    setModalContent({
      title: "Options",
      content: <div>More options coming soon</div>,
    });
    setModalOpen(true);
  };

  const handleDoubleClickOrTap = async (itemId) => {
    const currentTime = await new Date().getTime();
    const tapLength = (await currentTime) - lastTap.current;

    if (tapLength < 300 && tapLength > 0) {
      await toggleLike(itemId); // Register double click/tap if within 300ms
    }

    lastTap.current = await currentTime;
  };

  const toggleLike = async (itemId, resId) => {
    if (!user) {
      setShowLoginModal(true);
      return;
    }

    // If vibrate is supported, vibrate on like
    if (navigator.vibrate) {
      navigator.vibrate(50);
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_DOMAIN}/api/restaurant/${resId}/${itemId}/like`,
        {},
        { withCredentials: true }
      );

      // Update the liked status in menuItems
      setMenuItems((prevMenuItems) =>
        prevMenuItems.map((item) => {
          if (item._id === itemId) {
            return { ...item, liked: !item.liked };
          } else {
            return item;
          }
        })
      );
    } catch (error) {
      console.error("Error liking item:", error);
    }
  };

  const shareItem = async (
    itemId,
    resId,
    itemTitle,
    itemRestaurant,
    sharingUser
  ) => {
    try {
      // Determine sharer's identifier
      let sharerId = user ? user._id : getOrCreateMetaCookie();

      // Send POST request to create a new share
      const response = await axios.post(
        `${process.env.REACT_APP_DOMAIN}/api/shares/create`,
        {
          itemId,
          sharerId,
        }
      );

      const shareID = response.data.shareID;

      // Construct the shared URL with the shareID
      const sharedUrl = `https://platefol.com/discover?item=${itemId}&sid=${shareID}`;

      // Use the Web Share API
      await navigator.share({
        title: `Platefol - The Smart Restaurant Menu`,
        text: `Check out ${
          itemTitle.toLowerCase().startsWith("the ")
            ? itemTitle
            : `the ${itemTitle}`
        } at ${itemRestaurant} on Platefol!`,
        url: sharedUrl,
      });

      // Optionally, update the shareCount in the UI
    } catch (error) {
      console.error("Error sharing item:", error);
    }
  };

  const logShareClick = async (shareID) => {
    try {
      const recipientId = user ? user._id : getOrCreateMetaCookie();

      await axios.post(`${process.env.REACT_APP_DOMAIN}/api/shares/click`, {
        shareID,
        recipientId,
      });
    } catch (error) {
      console.error("Error logging share click:", error);
    }
  };

  // Helper function to get or create a meta tag cookie
  const getOrCreateMetaCookie = () => {
    let metaCookie = Cookie.get("meta_cookie");
    if (!metaCookie) {
      metaCookie = uuid(); // Generate a unique identifier
      Cookie.set("meta_cookie", metaCookie, { expires: 365 });
    }
    return metaCookie;
  };

  const resetExpanded = (e) => {
    e.stopPropagation();
    setExpandedItems({}); // Reset all to collapsed
  };

  // Expand item on click of `.item-text`
  const expandItem = (itemId) => {
    setExpandedItems((prevState) => ({
      ...prevState,
      [itemId]: !prevState[itemId], // Toggle expansion state for the clicked item
    }));
  };

  // Collapse item when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setExpandedItems({}); // Collapse all items if clicking outside
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const pauseVideo = (e) => {
    e.stopPropagation();
    document.querySelectorAll("video").forEach((video) => {
      video.pause();
    });
  };

  const unpauseVideo = (e) => {
    e.stopPropagation();
    document.querySelectorAll("video").forEach((video) => {
      video.play();
    });
  };

  const goToRestaurantMenu = async (e) => {
    e.stopPropagation();

    const currentIndex = inView !== null ? inView : 0; // Default to 0 if inView is null

    // Set lastURL in loaclStorage to the ccurrent URL
    await localStorage.setItem(
      "lastURL",
      `/r/${e.target.getAttribute(
        "restaurant"
      )}?discover=true&lat=${latitude}&lng=${longitude}&returnto=${currentIndex}`
    );

    await navigate(
      `/r/${e.target.getAttribute(
        "restaurant"
      )}?discover=true&lat=${latitude}&lng=${longitude}&returnto=${currentIndex}`
    );
  };

  const followRestaurant = async (e, restaurantId) => {
    e.stopPropagation();
    e.preventDefault();

    if (!user) {
      setShowLoginModal(true);
    } else {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_DOMAIN}/api/restaurant/${restaurantId}/follow`,
          {},
          { withCredentials: true }
        );

        // Update the 'following' status in menuItems
        setMenuItems((prevMenuItems) =>
          prevMenuItems.map((item) => {
            if (item.id === restaurantId) {
              return {
                ...item,
                following: !item.following,
              };
            } else {
              return item;
            }
          })
        );
      } catch (error) {
        console.error("Error following/unfollowing restaurant:", error);
      }
    }
  };

  const openMaps = async (e) => {
    e.stopPropagation();

    const encodedAddress = encodeURIComponent(e.target.getAttribute("address"));
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    let mapsUrl;

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      mapsUrl = `https://maps.apple.com/?daddr=${encodedAddress}`;
    } else if (/android/i.test(userAgent)) {
      mapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${encodedAddress}`;
    } else {
      mapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${encodedAddress}`;
    }

    await window.open(mapsUrl, "_blank");

    await axios.post(
      `${process.env.REACT_APP_DOMAIN}/api/restaurant/edit/referral`,
      {
        resId: e.target.getAttribute("restaurant"),
      }
    );
  };

  // Main return block for rendering the discover component
  return (
    <>
      <DiscoverToolbar toggleBottomToolbar={toggleBottomToolbar} />
      <div
        className={`ScrollMenu${
          showBottomToolbar ? " with-bottom-toolbar" : ""
        }`}
        ref={containerRef}
      >
        {menuItems.map((item, index) => (
          <div
            key={item._id}
            ref={(element) => {
              itemRefs.current[index] = element;
            }}
            onTouchStart={pauseVideo}
            onTouchEnd={unpauseVideo}
            onClick={() => handleDoubleClickOrTap(item._id)} // Double-click and double-tap handler
            id={`item-${item._id}`}
            item={item._id}
            className={`scrollItem`}
          >
            {item.content.src !== "/" ? (
              item.content.contentType === "img" ? (
                <>
                  <img
                    src={item.content.thumbnail}
                    className="item-image loading ken-burns"
                  />
                  <ImgFile
                    expanded={expandedItems[item._id]}
                    src={item.content.src}
                    alt={item.title}
                  />
                </>
              ) : (
                <>
                  <img
                    src={item.content.thumbnail}
                    className="item-image loading"
                  />
                  <AutoPlaySilentVideo
                    className={`item-image${
                      expandedItems[item._id] ? " expanded-gradient" : ""
                    }`}
                    src={item.content.src}
                    autoPlay
                    startPlaying={index === inView ? true : false}
                  />
                </>
              )
            ) : (
              <NoContent
                restaurantName={item.restaurantName}
                itemName={item.title}
              />
            )}
            <div className="gradient">
              <div className="item-content">
                <div
                  className="item-text"
                  onClick={(e) => {
                    e.stopPropagation();
                    expandItem(item._id);
                  }}
                >
                  <div className="item-price-container">
                    <span className="item-price">${item.price.toFixed(2)}</span>{" "}
                    |{" "}
                    <span className="restaurant-tag">
                      {item.restaurantName}
                      {item.verified ? (
                        <img src={verifiedBadge} className="verified-badge" />
                      ) : (
                        ""
                      )}
                    </span>
                    <span>
                      <button
                        className={`follow-button${
                          item.following ? " following" : ""
                        }`}
                        onClick={(e) => followRestaurant(e, item.id)}
                      >
                        {item.following ? "Following" : "Follow"}
                      </button>
                    </span>
                  </div>
                  <div className={`jost item-title`}>{item.title}</div>
                  <div
                    className={`item-description${
                      expandedItems[item._id] ? " expanded" : " collapsed"
                    }`}
                  >
                    {item.description}
                  </div>
                </div>
                <div className="interaction-toolbar">
                  <div
                    className="interaction-option"
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleLike(item._id, item.id);
                    }}
                  >
                    <img
                      className={`interaction-icon${
                        item.liked ? " liked" : ""
                      }`}
                      src={item.liked ? Like : EmptyLike}
                      alt="Like Button"
                    />
                    <span className="interaction-count">{item.likeCount}</span>
                  </div>
                  <div
                    onClick={(e) => openCommentModal(e, item)}
                    className="interaction-option"
                  >
                    <img
                      className="interaction-icon"
                      src={CommentIcon}
                      alt="Comment Button"
                    />
                    <span className="interaction-count">
                      {item.commentCount}
                    </span>
                  </div>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      // Share Item with Item ID and User ID
                      shareItem(
                        item._id,
                        item.id,
                        item.title,
                        item.restaurantName,
                        user?._id
                      );
                    }}
                    itemId={item._id}
                    className="interaction-option"
                  >
                    <img
                      className="interaction-icon"
                      id="share-icon"
                      src={ShareIcon}
                      alt="Share Button"
                    />
                    <span className="interaction-count">{item.shareCount}</span>
                  </div>
                  <div onClick={openKebabModal} className="interaction-option">
                    <img
                      className="interaction-icon"
                      id="kebab-icon"
                      src={KebabIcon}
                      alt="Options Button"
                    />
                  </div>{" "}
                </div>
              </div>
              <div className="action-buttons">
                <div className="primary-actions">
                  <div className="button-section">
                    <a
                      onClick={goToRestaurantMenu}
                      itemId={item._id}
                      restaurant={item.id}
                      className="action-button menu-button"
                    >
                      See Menu
                    </a>
                  </div>
                  <div className="button-section">
                    <a
                      onClick={openMaps}
                      address={item.location}
                      restaurant={item.id}
                      className="action-button map-button"
                    >
                      Go Here
                    </a>
                  </div>
                </div>
                {item.content.src === "/" && (
                  <SecondaryActions
                    restaurantId={item.id}
                    itemId={item.itemId}
                  />
                )}
              </div>
            </div>
          </div>
        ))}
        {loadingRef.current && <div>Loading...</div>}
        <Modal
          show={isModalOpen}
          onClose={handleCloseModal}
          header={modalContent?.title}
        >
          {modalContent?.content}
        </Modal>
        {showLoginModal && <Login exit={() => setShowLoginModal(false)} />}
        <BottomToolbar
          show={showBottomToolbar}
          active="discover"
          toggleBottomToolbar={toggleBottomToolbar}
          user={user}
          dark={true}
        />
      </div>
    </>
  );
};

export default React.memo(Discover);
